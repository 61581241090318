/* stylelint-disable at-rule-empty-line-before */
/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable media-feature-range-notation */
/* stylelint-disable order/properties-alphabetical-order */

/* form container */
.lp-flex-container {
  border-radius: 24px;
  background: #f0f2f7;
  max-width: 100% !important;
}

.inner-row-div {
  max-width: 100% !important;
}

.main-form-wrapper {
  padding: 80px;
}

/* hide limit count label */
.limit-counter {
  display: none !important;
}

.iti__selected-country {
  background-color: rgb(255 255 255 / 5%) !important;
}

/* horizontal red line */
.row-slot-wrapper > :first-child:after {
  content: '';
  position: absolute;
  width: 77px;
  height: 4px;
  background: #d92c29;
}

/* date input position */
.landing-page-slot > .lp-flex-container > .date_input-component {
  margin-top: 1px;
  margin-bottom: -3px;
}

/* date label position */
.date_input-component > label > div.form-input-wrapper-div {
  margin-top: 45px !important;
}

.text-component {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: GothamSSmBold !important;
  color: #121111 !important;
}

.submit-button {
  width: auto !important;
}

/* Description Text Section width  */
.row-slot-wrapper > .lp-flex-container:first-child {
  max-width: 556px !important;
}

/* Form Gap */
.form-page
  > .landing-page-row:nth-child(1)
  > .inner-row-div
  > .border-wrapper
  > .row-slot-wrapper
  > :last-child
  > .slot-container {
  display: grid !important;
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  gap: 21px;
}

/* date_input */
.params-date_input {
  text-transform: uppercase !important;
  min-height: 56px !important;
}

/* Textarea height */
.params-longtext_input {
  min-height: 264px !important;
}

.shared-input-field .params-text_input {
  border-radius: 120px !important;
}

.params-email_input {
  border-radius: 120px !important;
}

/* Required Error Message - Default State */
.form-field-required {
  display: none !important;
}

/* Required Error Message - Error State */
.email_input-component > .form-input-error-message,
.phone_input-component > .form-input-error-message,
.lp-flex-container > .required-component > .form-input-error-message {
  color: #b32b30 !important;
  font-size: 12px !important;
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: 'Noto Sans' !important;
  white-space: pre-wrap !important;
}

/* Form field - Label Default State */
.form-input-wrapper-element .form-field-label {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease, margin 0.3s ease;
  margin-top: 0;
  position: absolute;
  background-color: #ffffff;
  margin-left: 17px;
  padding: 4px;
  line-height: 140% !important;
}

/* Form field - Label Active State */
.form-input-wrapper-element:focus-within .form-field-label {
  opacity: 1;
  transform: translateY(-10px);
  margin-top: 0;
  display: block;
  height: 17px;
  min-height: 17px;
  margin-left: 17px;
}

/* Form field - Date Label Active State */
.date_input-component > label > span.form-field-label {
  background-color: #eef0f4 !important;
  opacity: 1;
  transform: translateY(0) !important;
  font-weight: 600 !important;
}

/* Form field - Required Border */
.form-input-error-field .form-input-wrapper-div {
  border: 2px solid #b32b30 !important;
}

/* Form field - Hover Border */
.form-input-wrapper-div:hover {
  border: 1px solid #0b40a3 !important;
}

/* Form field - Active Border */
.form-input-wrapper-element:focus-within .form-input-wrapper-div {
  border: 2px solid #000000 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
}

/* Checkbox field - Active Outer Border */
.checkbox-field:focus-within .form-input-wrapper-div {
  border: 0 solid transparent !important;
  border-radius: 4px !important;
  align-items: flex-start !important;
}

/* Checkbox field - hover Border */
.checkbox-field > .form-input-wrapper-element > .form-input-wrapper-div:hover {
  border: 0 solid transparent !important;
}

/* Form field - Terms Label Default State */
.checkbox-field > label > span.form-field-label {
  background-color: #eef0f4 !important;
  cursor: pointer !important;
  display: flex;
  margin-left: 40px !important;
  margin-top: -3px !important;
  opacity: 1;
  transform: translateY(0) !important;
}

/* Form field - Terms Label Default State */
.checkbox-field label {
  align-items: flex-start !important;
}

/* media queries for different viewpoints */
@media screen and (max-width: 400px) {
  .text_input-component {
    padding-right: 0 !important;
  }

  .spacer-component {
    display: none;
  }

  .form-input-checkbox-element {
    margin-top: 10px !important;
  }

  .mdi-check:before {
    margin-top: 5px !important;
  }

  .recaptcha-component {
    margin-top: 24px !important;
    margin-left: -21px !important;
  }

  .submit-button {
    margin-right: auto !important;
  }
}

@media (min-width: 401px) and (max-width: 1139px) {
  .landing-page-slot {
    padding-left: 40px !important;
  }

  .text_input-component {
    padding-right: 0 !important;
  }

  /* Description Text Section width  */
  .row-slot-wrapper > .lp-flex-container:first-child {
    max-width: 556px !important;
    padding-top: 24px !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }

  .spacer-component {
    display: none;
  }

  .form-input-checkbox-element {
    margin-top: 0 !important;
  }

  /* Deadline bottom space */
  .date_input-component > label > div.form-input-wrapper-div {
    margin-top: 53px !important;
  }

  .mdi-check:before {
    margin-top: 0 !important;
  }

  .recaptcha-component {
    margin-top: 0 !important;
  }

  .submit-button {
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 1140px) {
  .form-page
    > .landing-page-row:nth-child(1)
    > .inner-row-div
    > .border-wrapper
    > .row-slot-wrapper
    > :last-child
    > .slot-container {
    display: grid !important;
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .main-form-wrapper {
    padding: 24px;
  }

  .lp-flex-container {
    flex-direction: column;
  }
}
