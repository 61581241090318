@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Proxima Nova', system-ui, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    @apply font-sans;
    @apply text-base leading-none;
    @apply text-black leading-none;
  }

  /*
    Hides Sitecore editor markup,
    if you run the app in connected mode while the Sitecore cookies
    are set to edit mode.
  */
  .scChromeData,
  .scpm {
    display: none !important;
  }

  /*
    Styles for default JSS error components
  */
  .sc-jss-editing-error,
  .sc-jss-placeholder-error {
    background-color: #ffffe0;
    padding: 1em;
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;

      /* IE and Edge */
      scrollbar-width: none;

      /* Firefox */
    }
  }
}

/* Below code has been added to manage transition of Hamburger icon into Close icon during it's click */

/* stylelint-disable-next-line */
.toggle-menu input[type='checkbox']:checked ~ .hamburger-lines .line1 {
  transform: rotate(45deg) translate(5.5px, -8px);
}

/* stylelint-disable-next-line */
.toggle-menu input[type='checkbox']:checked ~ .hamburger-lines .line2 {
  opacity: 0;
  transform: scaleY(0);
}

/* stylelint-disable-next-line */
.toggle-menu input[type='checkbox']:checked ~ .hamburger-lines .line3 {
  margin-bottom: -3px;
  transform: rotate(-45deg) translate(8px, 6px);
}

/* Rich Text Editor */
.rich-text p {
  font-family: 'Noto Sans', sans-serif;
}

.rich-text {
  @apply bg-components-content-list-color-bg leading-bodySans-medium text-components-content-list-color-body;
}

.rich-text *:before {
  @apply bg-black;
}

.rich-text h1,
.richtext-wrapper h1 {
  @apply font-rte-small-h1
  text-rte-small-h1
  leading-rte-small-h1
  md:font-rte-medium-h1
  md:text-rte-medium-h1
  md:leading-rte-medium-h1
  lg:font-rte-large-h1
  lg:text-rte-large-h1
  lg:leading-rte-large-h1
  mb-spacing-spacing-5 text-components-content-list-color-title;
}

.rich-text h2,
.richtext-wrapper h2 {
  @apply font-rte-small-h2
  text-rte-small-h2
  leading-rte-small-h2 md:font-rte-medium-h2
  md:text-rte-medium-h2
  md:leading-rte-medium-h2 lg:font-rte-large-h2
  lg:text-rte-large-h2
  lg:leading-rte-large-h2 mb-spacing-spacing-5;
}

.rich-text h3,
.richtext-wrapper h3 {
  @apply font-rte-small-h3
  text-rte-small-h3
  leading-rte-small-h3 md:font-rte-medium-h3
  md:text-rte-medium-h3
  md:leading-rte-medium-h3 lg:font-rte-large-h3
  lg:text-rte-large-h3
  lg:leading-rte-large-h3 mb-spacing-spacing-5;
}

.rich-text h4,
.richtext-wrapper h4 {
  @apply font-rte-small-h4
  text-rte-small-h4
  leading-rte-small-h4 md:font-rte-medium-h4
  md:text-rte-medium-h4
  md:leading-rte-medium-h4 lg:font-rte-large-h4
  lg:text-rte-large-h4
  lg:leading-rte-large-h4 mb-spacing-spacing-5;
}

.rich-text h5,
.richtext-wrapper h5 {
  @apply font-rte-small-h5
  text-rte-small-h5
  leading-rte-small-h5 md:font-rte-medium-h5
  md:text-rte-medium-h5
  md:leading-rte-medium-h5 lg:font-rte-large-h5
  lg:text-rte-large-h5
  lg:leading-rte-large-h5 mb-spacing-spacing-5;
}

.rich-text h6,
.richtext-wrapper h6 {
  @apply font-rte-small-h6
  text-rte-small-h6
  leading-rte-small-h6 md:font-rte-medium-h6
  md:text-rte-medium-h6
  md:leading-rte-medium-h6 lg:font-rte-large-h6
  lg:text-rte-large-h6
  lg:leading-rte-large-h6 mb-spacing-spacing-5;
}

.rich-text p,
.richtext-wrapper p {
  @apply py-[16px] break-words;
}

.rich-text p:first-child,
.richtext-wrapper p:first-child {
  @apply pt-0;
}

.rich-text p:last-child,
.richtext-wrapper p:last-child {
  @apply pb-0;
}

/* stylelint-disable-next-line */
.rich-text a sup,
.richtext-wrapper a sup {
  @apply leading-bodySans-medium;
}

.rich-text ul,
.rich-text ol,
.richtext-wrapper ul,
.richtext-wrapper ol {
  @apply my-2 list-outside ml-4;
}

.rich-text ul,
.richtext-wrapper ul {
  @apply list-disc;
}

.rich-text ol,
.richtext-wrapper ol {
  @apply list-decimal max-md:break-words;
}

/* stylelint-disable-next-line */
.rich-text ol ul,
.richtext-wrapper ol ul {
  @apply ml-4;
}

.body-small-rte {
  font-size: 12px;
}

/* Anchor tag with animated hover effect */
.rich-text a,
.richtext-wrapper a {
  @apply underline hover:text-components-hyperlink-color-dark-hover inline relative text-components-hyperlink-color-dark-default visited:text-components-hyperlink-color-dark-visited leading-none;
}

.rich-text a[target*='_blank'],
.richtext-wrapper a[target*='_blank'] {
  @apply after:h-4 after:ml-2 after:relative after:top-1 after:w-4;
}

/* stylelint-disable-next-line */
.rich-text a svg,
.richtext-wrapper a svg {
  @apply fill-current h-4 w-4 inline-block no-underline;
}

.rich-text img,
.richtext-wrapper img {
  @apply rounded-themes-spacing-radius-small-image md:rounded-themes-spacing-radius-large-image;
}

/* To remove extra spaces between components */
.reduce-bottom-margin {
  margin-bottom: -3.5rem !important;
  max-height: 0 !important;
}

.reduce-top-margin {
  margin-top: -2.5rem !important;
  max-height: 0 !important;
}

.yt-video {
  @apply aspect-video rounded-themes-spacing-radius-small-image md:rounded-themes-spacing-radius-large-image w-full h-full max-w-[1200px] my-0 mx-auto;
}

#header.sticky {
  @apply w-full top-0;
}

#header.visually-none {
  @apply pointer-events-none opacity-0;
}

.editing-mode #header.sticky,
.editing-mode #header {
  @apply relative;
}

/* stylelint-disable-next-line */
.product-information-wrapper .thumbnail-slider .splide__slide {
  @apply border-2 border-solid rounded-themes-spacing-radius-large-image overflow-hidden border-transparent transition-all;
}

/* stylelint-disable-next-line */
.product-information-wrapper .thumbnail-slider .is-active {
  @apply border-2 border-solid rounded-themes-spacing-radius-large-image border-components-product-information-color-selected-stroke;
}

/* Evidon */

.evidon-consent-link-image {
  @apply inline-block;
}

/* Components inside accordion */

/* stylelint-disable-next-line */
div[data-testid='accordion'] div[data-testid='iconcard'] > div,
div[data-testid='accordion'] .rich-text .container > div {
  @apply pt-0;
  @apply px-0;
}

/* Image Wrapper Snippet */

/* stylelint-disable-next-line */
.richtext-wrapper .img-wrapper-ce {
  @apply flex mx-auto justify-center w-2/4;
}

/* Three Column Snippet */

/* stylelint-disable-next-line */
.richtext-wrapper .three-col-layout {
  @apply grid grid-cols-3;
}

/* stylelint-disable-next-line */
.richtext-wrapper .three-col-layout .sub-child {
  @apply flex justify-start;
}

/* spacer component */
.spacerClasses {
  @apply py-[40px] md:py-[64px] lg:py-[80px];
}

/* Video Popup */
.modal {
  left: 50%;
  max-width: 1062px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  @apply p-spacing-spacing-5;
}

.overlay {
  inset: 0;
  position: fixed;
  z-index: 999;

  @apply bg-components-lightbox-color-overlay-bg;
}

/* stylelint-disable-next-line selector-class-pattern */
#crl8-homepage-multisize-grid .qxyt6u-0.sc-160qg7w-0.Mjdlv {
  @apply mb-auto !flex items-center justify-center whitespace-nowrap disabled:cursor-not-allowed enabled:duration-150 enabled:transition hover:ease-in focus-visible:shadow-focus focus-visible:outline-0 bg-components-button-color-filled-brand-default-bg border-components-button-color-filled-brand-default-stroke border-3 gap-components-button-spacing-large-default-space-between min-h-12 py-components-button-spacing-large-default-padding-y px-components-button-spacing-large-default-padding-x rounded-themes-spacing-radius-large-button text-components-button-color-filled-brand-default-text [&>*]:gap-components-button-spacing-large-default-space-between active:bg-components-button-color-filled-brand-pressed-bg active:border-components-button-color-filled-brand-pressed-stroke disabled:bg-components-button-color-filled-brand-disabled-bg/components-button-color-filled-brand-disabled-bg disabled:border-transparent disabled:text-components-button-color-filled-brand-disabled-text/components-button-color-filled-brand-disabled-text [&_svg]:disabled:fill-components-button-color-filled-brand-disabled-icon/components-button-color-filled-brand-disabled-icon focus:bg-components-button-color-filled-brand-focus-bg focus:border-components-button-color-filled-brand-focus-stroke focus:shadow-focus [&_svg]:focus:fill-components-button-color-filled-brand-focus-icon hover:bg-components-button-color-filled-brand-hover-bg hover:border-components-button-color-filled-brand-hover-stroke hover:text-components-button-color-filled-brand-hover-text font-bodySans-medium-semibold leading-bodySans-medium-semibold inline-flex items-center justify-center relative text-bodySans-medium-semibold tracking-[0.16px];
}

.sc-102xyuw-2.iXDoYT {
  @apply !font-header-medium-large !leading-header-medium-large !text-header-medium-large;
}

.sc-102xyuw-3.eMjQdL {
  @apply !font-bodySans-medium !leading-bodySans-medium !text-bodySans-medium;
}

.image-caption {
  font-size: 14px;
  text-align: center;
}
