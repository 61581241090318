/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable no-descending-specificity */

/* Product information component - No Reviews */

body .p-w-r .pr-rid {
  @apply mt-spacing-padding-large-3 pt-spacing-padding-large-4;
}

body .p-w-r .pr-review-display .pr-rd-no-reviews {
  @apply p-0;
}

body .p-w-r .pr-required-indicator {
  @apply text-color-system-alert-400 !important;
}

body .p-w-r .pr-review-display .pr-rd-no-reviews a {
  @apply text-bodySans-large-underline-semibold font-bodySans-large-underline-semibold leading-bodySans-large-underline-semibold underline text-themes-color-text-black;
}

/* Product information component - Ratings and Review section starts here */

/* region */

body .p-w-r .pr-irlsnippet {
  @apply mb-[50px];
}

body .p-w-r .pr-irlsnippet-images .pr-irlsnippet-image-container {
  @apply overflow-hidden rounded-radius-radius-3 before:content-none;
}

body .p-w-r .pr-rid .pr-rid-tile {
  @apply rounded-radius-radius-3 !important;
}

body .p-w-r .pr-rid .pr-rid-tile-image {
  @apply bg-themes-color-background-light !important;
}

body .p-w-r .pr-rid .pr-rid-tile-image img {
  @apply max-h-full object-center !important;
}

body .p-w-r .pr-histogram-cross .pr-cross-icon {
  @apply ml-3 mt-0 [&>svg]:mt-[3px];
}

body .p-w-r .pr-snippet {
  @apply items-start lg:items-stretch !important;
}

body .p-w-r .pr-snippet div {
  @apply align-baseline whitespace-nowrap;
}

body .p-w-r .pr-snippet .pr-snippet-stars-png {
  @apply border-components-product-information-color-divider border-r border-solid flex pr-4;
}

body .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4 {
  @apply p-0 !important;
}

body .p-w-r .pr-snippet .pr-snippet-stars-png .pr-snippet-rating-decimal {
  @apply p-0;
}

body .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-stars-reco-stars {
  @apply mb-6;
}

.pr-snippet-rating-decimal {
  @apply text-bodySans-medium-semibold font-bodySans-medium-semibold leading-bodySans-medium-semibold text-components-product-information-color-detail-text border-0 !important;
}

.pr-helpful-btn:hover span {
  @apply text-themes-color-background-white !important;
}

body .p-w-r .pr-review-display span {
  @apply font-bodySans-large font-bodySans-large leading-bodySans-large text-bodySans-large text-themes-color-text-dark inline-block w-auto ml-spacing-space-between-large-2;
}

body .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write {
  @apply items-stretch;
}

body .p-w-r .pr-review-snapshot .pr-snippet .pr-snippet-read-and-write {
  @apply m-auto;
}

body .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write a,
body .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write span {
  @apply font-bodySans-medium-semibold items-center leading-bodySans-medium-semibold my-0 no-underline px-0 py-[7px] text-bodySans-medium-semibold text-components-button-color-outline-brand-default-text md:m-0 !important;
}

body .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write .pr-snippet-review-count {
  @apply border-components-product-information-color-divider border-r border-solid pr-components-product-information-spacing-large-space-between !important;
}

.pr-snippet-stars-reco-reco {
  @apply mb-components-product-information-spacing-large-title-margin-bottom !important;
}

body .p-w-r .pr-review-snippet-container .pr-snippet-reco-to-friend {
  @apply flex flex-row items-center;
}

body
  .p-w-r
  .pr-review-snippet-container
  .pr-snippet-reco-to-friend
  .pr-snippet-reco-to-friend-percent
  span {
  @apply align-middle font-bodySans-medium-semibold leading-bodySans-medium-semibold text-bodySans-medium-semibold text-components-product-information-color-headline !important;
}

body .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-reco {
  @apply p-0;
}

body .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-reco span {
  @apply font-header-medium-xSmall text-header-medium-xSmall text-header-small-xLarge text-themes-color-text-black md:mb-spacing-padding-large-3 md:text-header-large-xLarge md:font-header-large-xLarge md:leading-header-large-xLarge !important;
}

body
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-simple
  .pr-snippet-reco-to-friend-yellow
  .pr-reco
  span {
  @apply font-header-small-xLarge leading-header-small-xLarge text-header-small-xLarge text-themes-color-text-black md:font-header-large-xLarge md:leading-header-large-xLarge md:text-header-large-xLarge;
}

body
  .p-w-r
  .pr-snippet-reco-to-friend
  .pr-snippet-reco-to-friend-percent.pr-snippet-reco-to-friend-green {
  @apply text-nowrap !inline-flex flex-col sm:flex-row items-center gap-2 sm:gap-0;
}

body .p-w-r .pr-snippet-reco-to-friend,
body .p-w-r .pr-snippet-reco-to-friend .pr-reco-to-friend-message {
  @apply max-w-full;
}

body .p-w-r .pr-snippet-reco-to-friend .pr-reco-to-friend-message {
  @apply font-bodySans-small leading-bodySans-small max-w-full text-bodySans-small text-components-product-information-color-detail-text md:font-bodySans-medium-semibold md:leading-bodySans-medium-semibold md:text-bodySans-medium-semibold !important;
}

body .p-w-r .pr-review-snapshot .pr-snippet-reco-to-friend,
body .p-w-r .pr-review-snapshot .pr-snippet-reco-to-friend .pr-reco-to-friend-message {
  @apply font-bodySans-small-semibold leading-bodySans-small-semibold m-auto max-w-[300px] text-bodySans-small-semibold text-themes-color-text-black md:font-bodySans-large-semibold md:leading-bodySans-large-semibold md:text-bodySans-large-semibold !important;
}

/* end region */

/* region */

body .p-w-r .pr-snippet.pr-category-snippet {
  @apply mb-components-product-card-spacing-large-title-margin-bottom;
}

body .p-w-r .pr-snippet.pr-category-snippet .pr-snippet-stars {
  @apply bg-transparent border-none p-0;
}

/* end region */

/* Product information component - Ratings and Review section ends here */

/* --------------------------------------------------------------------------------------- */

/* Product Detail Page Power reviews : Section 1 (Titles and Intro) Start here */

/* region */

.pr-review-heading {
  @apply font-header-small-large font-header-small-large text-header-small-large leading-header-small-large text-themes-general-card-color-dark-title pb-spacing-spacing-4 mb-spacing-spacing-2 md:font-header-large-large md:font-header-large-large md:text-header-large-large md:leading-header-large-large !important;
}

#pr-reviewdisplay #pr-review-heading {
  @apply md:font-header-large-large md:font-header-large-large md:text-header-large-large md:leading-bodySans-large font-header-small-large font-header-small-large text-header-small-large leading-header-small-large text-themes-general-card-color-dark-title;
}

#pr-reviewdisplay body .p-w-r a:before,
#pr-reviewdisplay body .p-w-r a:after {
  @apply content-none !important;
}

body .p-w-r .pr-review-snapshot .pr-review-snapshot-header-intro {
  @apply border-0 flex flex-col gap-spacing-margin-small-3 p-0 md:items-end md:flex-row md:gap-0 md:gap-spacing-margin-small-6 !important;
}

body .p-w-r .pr-review-snapshot .pr-review-snapshot-header .pr-headline {
  @apply font-header-small-xSmall leading-header-small-xSmall pr-0 text-header-small-xSmall text-themes-color-text-black md:font-header-large-xSmall md:leading-header-large-xSmall md:text-header-large-xSmall !important;
}

body .p-w-r .pr-review-snapshot .pr-faceoff-review:before,
body .p-w-r .pr-review-snapshot .pr-faceoff-review:after,
body .p-w-r .pr-review-snapshot .pr-faceoff-review span:before,
body .p-w-r .pr-review-snapshot .pr-faceoff-review span:after {
  content: none !important;
}

body .p-w-r .pr-review-snapshot .pr-faceoff-review,
body .p-w-r .pr-review-snapshot .pr-faceoff-review span {
  @apply font-bodySans-medium leading-bodySans-medium text-bodySans-medium text-themes-color-text-dark mb-spacing-margin-large-3 md:font-bodySans-large md:leading-bodySans-large md:mb-spacing-padding-large-5 md:text-bodySans-large;
}

body .p-w-r .pr-review-snapshot .pr-review-snapshot-header .pr-subheadline span {
  @apply text-themes-color-text-dark;
}

body .p-w-r .pr-review-snapshot.pr-snapshot-mobile .pr-review-snapshot-header .pr-subheadline,
body .p-w-r .pr-review-snapshot .pr-review-snapshot-header .pr-subheadline {
  @apply font-bodySans-medium-semibold gap-spacing-margin-small-2 leading-bodySans-medium-semibold text-bodySans-medium-semibold;
}

body .p-w-r .pr-review-snapshot .pr-review-snapshot-header .pr-subheadline a {
  @apply font-bodySans-medium-underline leading-bodySans-medium-underline text-bodySans-medium-underline text-components-hyperlink-color-dark-default;
}

/* end region */

/* Product Detail Page Power reviews : Section 1 (Titles and Intro) Ends here */

/* --------------------------------------------------------------------------------------- */

/* Product Detail Page Power reviews : Section 2 (Review Snapshots) Start here */

/* region */

body .p-w-r .pr-review-snapshot .pr-review-snapshot-simple {
  @apply border border-solid border-themes-color-stroke-light md:mt-0;
}

.pr-review-snapshot-block {
  @apply p-spacing-padding-large-5 !important;
}

.pr-snippet-stars {
  @apply gap-spacing-space-between-large-2 flex flex-row items-center !important;
}

body .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-block {
  @apply md:px-spacing-padding-large-5 md:py-spacing-padding-large-5 py-spacing-padding-large-3 px-spacing-spacing-5 mb-0 !important;
}

body .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-block-snippet,
body .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-block-recommend {
  @apply border-0 border-b border-solid border-themes-color-stroke-light md:border-b-0 md:border-r !important;
}

.pr-review-snapshot-block-container {
  @apply bg-themes-color-background-white border border-themes-color-stroke-light flex-col rounded-themes-spacing-radius-small-card md:flex-row md:items-stretch md:rounded-themes-spacing-radius-large-card !important;
}

.pr-review-snapshot-snippets-headline {
  @apply font-header-small-xLarge leading-header-small-xLarge text-header-small-xLarge text-themes-color-text-black md:text-header-large-xLarge md:font-header-large-xLarge md:leading-header-large-xLarge !important;
}

.pr-review-snapshot-snippets .pr-snippet-stars-container {
  @apply m-auto;
}

.pr-snippet {
  @apply flex flex-col items-center gap-components-product-information-spacing-small-space-between lg:flex-row lg:items-start !important;
}

.pr-review-snapshot-snippets .pr-snippet,
.pr-snippet-read-and-write {
  @apply flex flex-col !important;
}

.pr-snippet-stars-reco-stars .pr-snippet-read-and-write {
  @apply flex flex-row m-0 gap-components-product-information-spacing-small-space-between !important;
}

.pr-rating-stars div {
  @apply bg-components-product-card-color-star-rating;

  background-image: none !important;
  height: 24px !important;
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m233-80 65-281L80-550l288-25 112-265 112 265 288 25-218 189 65 281-247-149L233-80Z"/></svg>');
  width: 24px !important;
}

.pr-rating-stars div.pr-star-v4-0-filled {
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m354-247 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-80l65-281L80-550l288-25 112-265 112 265 288 25-218 189 65 281-247-149L233-80Zm247-350Z"/></svg>');
}

.pr-rating-stars div.pr-star-v4-100-filled {
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m233-80 65-281L80-550l288-25 112-265 112 265 288 25-218 189 65 281-247-149L233-80Z"/></svg>');
}

.pr-rating-stars div.pr-star-v4-75-filled {
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m233-80 65-281L80-550l288-25 112-265 112 265 288 25-218 189 65 281-247-149L233-80Z"/></svg>');
}

.pr-rating-stars div.pr-star-v4-50-filled {
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" ><path d="m480-323 126 77-33-144 111-96-146-13-58-136v312ZM233-80l65-281L80-550l288-25 112-265 112 265 288 25-218 189 65 281-247-149L233-80Z"  /></svg>');
}

.pr-rating-stars div.pr-star-v4-25-filled {
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" ><path d="m480-323 126 77-33-144 111-96-146-13-58-136v312ZM233-80l65-281L80-550l288-25 112-265 112 265 288 25-218 189 65 281-247-149L233-80Z"  /></svg>');
}

body .p-w-r .pr-review-snapshot .pr-snippet .pr-snippet-stars {
  @apply border-0 flex-col gap-spacing-spacing-4 after:content-none before:content-none md:flex-row !important;
}

body .p-w-r .pr-review-snapshot .pr-snippet .pr-snippet-stars .pr-rating-stars {
  @apply order-2;
}

body .p-w-r .pr-review-snapshot .pr-snippet .pr-snippet-stars .pr-snippet-rating-decimal {
  @apply order-1;
}

body .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-snippet-read-and-write {
  @apply flex flex-col gap-spacing-margin-large-3 items-center;
}

body .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets-headline {
  @apply md:mb-spacing-padding-large-3 md:text-header-large-xLarge md:font-header-large-xLarge md:leading-header-large-xLarge !important;
}

#pr-reviewdisplay {
  @apply bg-themes-color-background-light py-spacing-padding-large-5 px-spacing-padding-large-3 md:py-spacing-padding-large-6 md:px-spacing-padding-large-7;
}

body .p-w-r,
.pr-review-heading {
  @apply container m-auto;
}

body .p-w-r .pr-review-display .pr-snippet-rating-decimal,
body .p-w-r .pr-snippet.pr-category-snippet .pr-snippet-rating-decimal,
body .p-w-r .pr-read-review .pr-snippet-rating-decimal,
body .p-w-r .pr-review-snapshot .pr-review-snapshot-faceoff .pr-snippet-rating-decimal {
  display: none;
}

body .p-w-r .pr-review-snapshot .pr-snippet .pr-snippet-rating-decimal {
  @apply order-1 text-header-small-xLarge text-themes-color-text-black !important;
}

body
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-simple
  .pr-review-snapshot-snippets
  .pr-snippet-review-count {
  @apply font-bodySans-large-semibold font-bodySans-large-semibold text-bodySans-large-semibold leading-bodySans-large-semibold m-0 text-themes-color-text-black underline after:content-none before:content-none;
}

body .p-w-r .pr-review-snapshot {
  @apply bg-transparent;
}

body .p-w-r .pr-review-snapshot .pr-review-snapshot-faceoff .pr-snippet-stars {
  @apply mb-spacing-margin-large-3;
}

.pr-snippet-reco-to-friend-percent {
  @apply bg-transparent !important;
}

.pr-reco-to-friend-message {
  @apply font-bodySans-small-semibold text-bodySans-small-semibold leading-bodySans-small-semibold m-0 p-0 text-themes-color-text-black md:font-bodySans-large-semibold md:text-bodySans-large-semibold md:leading-bodySans-large-semibold !important;
}

body
  .p-w-r
  .pr-review-snapshot.pr-snapshot-mobile
  .pr-review-snapshot-simple
  .pr-snippet-reco-to-friend {
  @apply flex flex-col gap-4 text-center;
}

body
  .p-w-r
  .pr-review-snapshot.pr-snapshot-mobile
  .pr-review-snapshot-simple
  .pr-reco-to-friend-message {
  @apply text-center;
}

body .p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-histogram-label,
body .p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-histogram-count {
  @apply font-bodySans-small-semibold leading-bodySans-small-semibold text-bodySans-small-semibold text-themes-color-text-black;
}

body .p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-histogram-count {
  @apply px-0 w-[55px];
}

body .p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-histogram-cross {
  @apply w-0;
}

body .p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-histogram-label {
  @apply px-0 w-[50px];
}

body .p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-histogram-stars {
  @apply h-auto p-spacing-padding-large-1 table !important;
}

.pr-ratings-histogram-bar {
  @apply bg-themes-color-background-light h-[24px] mx-3 rounded-radius-radius-1 !important;
}

.pr-ratings-histogram-barValue {
  @apply bg-themes-color-background-accent1 h-[24px] rounded-radius-radius-1 !important;
}

/* end region */

/* Product Detail Page Power reviews : Section 2 (Review Snapshots) Ends here */

/* --------------------------------------------------------------------------------------- */

/* Product Detail Page Power reviews : Section 3 (Faceoff Snapshots) Starts here */

/* region */

body .p-w-r .pr-review-snapshot .pr-faceoff-img {
  @apply border-none float-none mx-auto z-10 md:float-left !important;
}

.pr-review-snapshot-faceoff {
  @apply border-0 p-0 flex flex-col md:flex-row !important;
}

.pr-review-snapshot-faceoff .pr-faceoff-positive {
  @apply mr-spacing-margin-large-5 !important;
}

.pr-review-snapshot-faceoff .pr-faceoff-positive,
.pr-review-snapshot-faceoff .pr-faceoff-negative {
  @apply bg-themes-color-background-white p-spacing-padding-large-3 border border-solid border-themes-color-stroke-light rounded-themes-spacing-radius-large-image-card md:p-spacing-padding-large-5 !important;
}

body .p-w-r .pr-read-review .pr-snippet-stars:after,
body .p-w-r .pr-read-review .pr-snippet-stars:before,
body .p-w-r .pr-review-display .pr-snippet-stars:after,
body .p-w-r .pr-review-display .pr-snippet-stars:before {
  content: none;
}

body .p-w-r .pr-review-snapshot .pr-review-snapshot-faceoff .pr-snippet-stars:before,
body .p-w-r .pr-review-snapshot .pr-review-snapshot-faceoff .pr-snippet-stars:after {
  content: none;
}

body .p-w-r .pr-review-snapshot .pr-faceoff-title {
  @apply font-header-small-xSmall leading-header-small-xSmall mb-spacing-margin-large-3 text-header-small-xSmall text-themes-color-text-black md:font-header-large-xSmall md:text-header-large-xSmall md:leading-header-large-xSmall !important;
}

body .p-w-r .pr-review-snapshot .pr-faceoff-readcomplete,
body .p-w-r .pr-review-snapshot .pr-faceoff-readcomplete-expanded {
  @apply font-bodySans-medium-semibold leading-bodySans-medium-semibold text-bodySans-medium-semibold text-themes-color-text-brand1 relative pt-components-button-spacing-compressed-default-padding-y !important;
}

body .p-w-r .pr-review-display header.pr-rd-content-block {
  @apply w-full md:mb-spacing-margin-large-3;
}

body .p-w-r .pr-review-display footer.pr-rd-content-block {
  @apply w-full mb-0 pb-0;
}

body .p-w-r .pr-review-display .pr-thumbs-icon svg {
  @apply h-full w-full;
}

body .p-w-r .pr-review-snapshot .pr-faceoff-readcomplete .pr-caret-icon svg,
body .p-w-r .pr-review-snapshot .pr-faceoff-readcomplete .pr-caret-icon__line,
body .p-w-r .pr-review-snapshot .pr-faceoff-readcomplete-expanded .pr-caret-icon__line {
  @apply text-themes-color-text-brand1 stroke-themes-color-text-brand1 !important;
}

body .p-w-r .pr-review-snapshot .pr-faceoff-readcomplete .pr-caret-icon svg {
  @apply rotate-[180deg] mt-[2px];
}

body .p-w-r .pr-review-snapshot .pr-faceoff-label {
  @apply font-bodySans-medium-semibold leading-bodySans-medium-semibold text-bodySans-medium-semibold mb-spacing-spacing-4 md:font-bodySans-large-semibold md:leading-bodySans-large-semibold md:text-bodySans-large-semibold;
}

.pr-faceoff-img-txt {
  @apply w-[80px] h-[80px] rounded-themes-spacing-radius-large-button-icon border-2 border-solid border-themes-color-stroke-light !important;
}

.pr-faceoff-img .pr-faceoff-img-txt {
  @apply font-header-medium-medium leading-header-medium-medium lowercase text-header-medium-medium text-themes-color-text-black flex justify-center md:font-header-large-medium md:leading-header-large-medium md:text-header-large-medium !important;
}

body .p-w-r .pr-review-snapshot .pr-faceoff-img-txt {
  @apply border border-solid border-themes-color-stroke-light flex items-center left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 !important;
}

/* end region */

/* Product Detail Page Power reviews : Section 3 (Faceoff Snapshots) Ends here */

/* --------------------------------------------------------------------------------------- */

/* BEGIN: Review List Filter */

body .p-w-r .pr-review-display.pr-rd-display-desktop #pr-rd-main-header {
  @apply flex justify-between items-center w-full flex-row mb-spacing-margin-large-3;
}

body .p-w-r .pr-review-display.pr-rd-display-desktop #pr-rd-main-header h1 {
  @apply flex w-full;
}

body .p-w-r .pr-review-display .pr-rd-main-header {
  @apply bg-transparent p-0;
}

body .p-w-r .pr-review-display .pr-rd-main-header .pr-rd-review-total {
  @apply block font-header-small-xSmall leading-header-small-xSmall mb-spacing-spacing-4 text-header-small-xSmall text-themes-color-text-black md:font-header-large-xSmall md:leading-header-large-xSmall md:text-header-large-xSmall;
}

body .p-w-r .pr-read-review .pr-rd-sort,
body .p-w-r .pr-review-display .pr-rd-sort {
  @apply border border-components-dropdown-border-default font-bodySans-medium leading-bodySans-medium rounded py-components-dropdown-input-padding-x px-components-dropdown-input-padding-x text-bodySans-medium;
}

/* END: Review List Filter */

/* Product Detail Page Power reviews : Section 4 (PR Review Details Section) Starts here */

/* region */

/* end region */

/* Product Detail Page Power reviews : Section 4 (PR Review Details Section) Ends here */

body
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-simple
  .pr-review-snapshot-snippets
  .pr-snippet-read-and-write
  a:active {
  @apply border border-[3px] border-components-button-color-filled-brand-focus-stroke;
}

body
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-simple
  .pr-review-snapshot-snippets
  .pr-snippet-read-and-write
  a:focus {
  @apply border border-[3px] border-components-button-color-filled-brand-focus-stroke shadow-focus;
}

body
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-simple
  .pr-review-snapshot-snippets
  .pr-snippet-read-and-write
  a {
  @apply border border-[3px] border-components-button-color-filled-brand-focus-stroke bg-components-button-color-filled-brand-default-bg border-components-button-color-filled-brand-default-stroke border-3 font-normal gap-components-button-spacing-large-default-space-between py-components-button-spacing-large-default-padding-y px-components-button-spacing-large-default-padding-x rounded-themes-spacing-radius-large-button text-base text-components-button-color-filled-brand-default-text [&>*]:gap-components-button-spacing-large-default-space-between active:bg-components-button-color-filled-brand-pressed-bg active:border-components-button-color-filled-brand-pressed-stroke disabled:bg-components-button-color-filled-brand-disabled-bg/components-button-color-filled-brand-disabled-bg disabled:border-transparent disabled:text-components-button-color-filled-brand-disabled-text/components-button-color-filled-brand-disabled-text [&_svg]:disabled:fill-components-button-color-filled-brand-disabled-icon/components-button-color-filled-brand-disabled-icon active:focus:bg-components-button-color-filled-brand-pressed-bg active:focus:border-components-button-color-filled-brand-pressed-bg active:focus:shadow-focus active:focus:border-3 active:focus:text-components-button-color-filled-brand-default-text focus:bg-components-button-color-filled-brand-focus-bg focus:border-components-button-color-filled-brand-focus-stroke focus:border-3 focus:text-components-button-color-filled-brand-default-text [&_svg]:focus:fill-components-button-color-filled-brand-focus-icon hover:bg-components-button-color-filled-brand-hover-bg hover:border-components-button-color-filled-brand-hover-stroke hover:text-components-button-color-filled-brand-hover-text [&_svg]:hover:fill-components-button-color-filled-brand-hover-icon;
}

body .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header {
  @apply bg-transparent px-0 m-0;
}

body .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header h1 {
  @apply font-header-large-xSmall font-header-large-xSmall leading-header-large-xSmall text-header-large-xSmall text-themes-color-text-black;
}

body .p-w-r .pr-review-display .pr-rd-sort {
  @apply md:min-h-[58px];
}

body .p-w-r .pr-review-display .pr-review {
  @apply border border-solid border-themes-color-stroke-light p-spacing-padding-large-3 rounded-themes-spacing-radius-large-image-card bg-themes-color-background-white;
}

body .p-w-r .pr-read-review.pr-rd-display-desktop .pr-review,
body .p-w-r .pr-read-review.pr-rd-display-tablet .pr-review,
body .p-w-r .pr-review-display.pr-rd-display-desktop .pr-review,
body .p-w-r .pr-review-display.pr-rd-display-tablet .pr-review {
  @apply border border-solid border-themes-color-stroke-light md:p-spacing-padding-large-5 bg-themes-color-background-white rounded-themes-spacing-radius-large-image-card relative !important;
}

body .p-w-r .pr-review-display {
  @apply bg-transparent;
}

.product-card-listing body .p-w-r .pr-snippet-stars {
  @apply bg-transparent border-0 mb-components-product-card-spacing-large-title-margin-bottom;
}

body .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-star-rating {
  @apply mb-spacing-margin-large-3;

  float: none;
}

#pr-reviewdisplay body .p-w-r .pr-review-display .pr-rd-review-headline {
  @apply font-header-small-xSmall leading-header-small-xSmall m-0 text-header-small-xSmall text-themes-color-text-black mb-spacing-margin-large-3 md:font-header-large-xSmall md:text-header-large-xSmall md:leading-header-large-xSmall;
}

body .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-side-content-block {
  @apply min-w-[280px];
}

body .p-w-r .pr-read-review .pr-rd-footer .pr-rd-bold {
  @apply font-header-small-xSmall inline-block leading-header-small-xSmall text-header-small-xSmall md:mb-0 mb-spacing-margin-large-3 text-themes-color-text-black w-auto ml-0 md:font-header-large-xSmall md:leading-header-large-xSmall md:text-header-large-xSmall !important;
}

body .p-w-r .pr-review-display .pr-helpful-btn {
  @apply bg-themes-color-background-white border border-solid border-themes-color-stroke-light flex gap-spacing-space-between-small-3 items-center mb-spacing-spacing-5 p-spacing-spacing-3 rounded-radius-radius-1 md:mb-0 !important;
}

#pr-reviewdisplay body .p-w-r .pr-review-display span {
  @apply m-0 !important;
}

#pr-reviewdisplay body .p-w-r .pr-review-display .pr-helpful-count {
  @apply font-bodySans-large leading-bodySans-large text-bodySans-large;
}

body .p-w-r .pr-review-display .pr-helpful-btn:hover {
  @apply bg-themes-color-text-brand1 !important;
}

.pr-thumbs-icon.pr-thumbs-icon-up {
  @apply ml-0;
}

body .p-w-r .pr-review-display .pr-rd-helpful-action-btn-group {
  @apply flex flex-row gap-[8px] md:mx-spacing-spacing-5 mb-0;
}

body .p-w-r .pr-review-display .pr-thumbs-icon {
  @apply flex h-6 w-6;
}

.pr-thumbs-icon.pr-thumbs-icon-up path,
.pr-thumbs-icon.pr-thumbs-icon-down path {
  @apply fill-themes-color-text-brand1;
}

.pr-helpful-btn:hover .pr-thumbs-icon.pr-thumbs-icon-up path,
.pr-helpful-btn:hover .pr-thumbs-icon.pr-thumbs-icon-down path {
  @apply fill-themes-color-background-white;
}

body .p-w-r .pr-rd-flag-review-btn {
  @apply font-bodySans-medium-underline-semibold leading-bodySans-medium-underline-semibold text-bodySans-medium-underline-semibold text-themes-color-text-brand1 md:font-bodySans-large-underline-semibold md:leading-bodySans-large-underline-semibold md:text-bodySans-large-underline-semibold;
}

body .p-w-r .pr-review-display .pr-rd-description {
  @apply flex flex-col-reverse mb-spacing-margin-large-5 static w-full md:w-[73%] !important;
}

body .p-w-r .pr-review-display .pr-rd-merchant-response {
  @apply bg-themes-color-background-light border border-solid border-themes-color-stroke-light mb-spacing-margin-large-5 rounded-themes-spacing-radius-small-card p-spacing-padding-large-2 !important;
}

body .p-w-r .pr-review-display .pr-rd-merchant-response .pr-rd-description-text {
  @apply block !important;
}

body .p-w-r .pr-review-display .pr-rd-merchant-response .pr-rd-description-text a {
  @apply underline hover:text-components-hyperlink-color-dark-hover inline-flex relative text-components-hyperlink-color-dark-default visited:text-components-hyperlink-color-dark-visited;
}

body .p-w-r .pr-review-display.pr-rd-display-desktop footer .pr-rd-content-block {
  @apply w-full md:flex md:items-center;
}

body .p-w-r .pr-review-display .pr-rd-review-position span:first-child {
  @apply pr-2;
}

body .p-w-r .pr-review-display .pr-rd-description .pr-rd-side-content-block.pr-rd-right {
  @apply bg-themes-color-background-white border border-solid border-themes-color-stroke-light md:mb-spacing-margin-large-5 mb-spacing-margin-large-3 order-1 p-spacing-padding-large-2 relative right-0 z-10 md:ml-spacing-margin-large-5 md:my-0 md:p-spacing-padding-large-3 md:absolute md:top-[40px] md:right-[40px] !important;
}

/* pr-rd-reviewer-details section */

body .p-w-r .pr-review-display .pr-rd-inner-side-content-block .pr-rd-details,
body .p-w-r .pr-review-display .pr-rd-side-content-block .pr-rd-details {
  @apply flex flex-row items-center mb-spacing-margin-large-2 !important;
}

/* body .p-w-r .pr-review-display .pr-rd-side-content-block .pr-rd-details:last-child {
  @apply mb-0 !important;
} */

body .p-w-r .pr-review-display .pr-rd-inner-side-content-block .pr-rd-details span,
body .p-w-r .pr-review-display .pr-rd-side-content-block .pr-rd-details span {
  @apply m-0 flex flex-row items-center gap-0 !important;
}

body .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-reviewer-details {
  @apply mb-0;
}

body .p-w-r .pr-review-display .pr-rd-reviewer-details span {
  @apply whitespace-nowrap;
}

body .p-w-r .pr-review-display .pr-rd-inner-side-content-block .pr-rd-details,
body .p-w-r .pr-review-display .pr-rd-side-content-block .pr-rd-details .pr-rd-bold,
body .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-review-disclosure {
  @apply font-bodySans-small-semibold font-bodySans-small-semibold text-bodySans-small-semibold leading-bodySans-small-semibold text-themes-color-text-dark !important;
}

/* pr-rd-reviewer-details section */

/* Pagination */

body .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-review-position {
  @apply mr-spacing-spacing-7;
}

body .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-review-position span {
  @apply font-bodySans-large-semibold font-bodySans-large-semibold text-bodySans-large-semibold leading-bodySans-large-semibold text-themes-color-text-dark;
}

body .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-to-top,
body .p-w-r .pr-review-display .pr-rd-pagination .pr-rd-pagination-btn {
  @apply font-bodySans-large-underline-semibold font-bodySans-large-underline-semibold text-bodySans-large-underline-semibold leading-bodySans-large-underline-semibold text-themes-color-text-brand1 block;
}

body .p-w-r .pr-review-display .pr-rd-pagination .pr-rd-pagination-btn span {
  @apply hidden;
}

/* Pagination */

body .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-description .pr-rd-details,
body
  .p-w-r
  .pr-review-display.pr-rd-display-desktop
  .pr-rd-description
  .pr-rd-details
  span:first-child {
  @apply mb-spacing-padding-large-3 flex flex-row gap-spacing-space-between-large-1;
}

body
  .p-w-r
  .pr-review-display.pr-rd-display-desktop
  .pr-rd-description
  .pr-rd-details.pr-rd-author-submission-date
  .pr-rd-bold,
body
  .p-w-r
  .pr-review-display.pr-rd-display-desktop
  .pr-rd-description
  .pr-rd-details.pr-rd-author-nickname
  .pr-rd-bold,
body
  .p-w-r
  .pr-review-display.pr-rd-display-desktop
  .pr-rd-description
  .pr-rd-details.pr-rd-author-location
  .pr-rd-bold {
  @apply font-bodySans-small-semibold font-bodySans-small-semibold text-bodySans-small-semibold leading-bodySans-small-semibold text-themes-color-text-dark;
}

body
  .p-w-r
  .pr-review-display.pr-rd-display-desktop
  .pr-rd-description
  .pr-rd-details.pr-rd-author-submission-date
  span:last-child,
body
  .p-w-r
  .pr-review-display.pr-rd-display-desktop
  .pr-rd-description
  .pr-rd-details.pr-rd-author-nickname
  span:last-child,
body
  .p-w-r
  .pr-review-display.pr-rd-display-desktop
  .pr-rd-description
  .pr-rd-details.pr-rd-author-location
  span:last-child {
  @apply font-bodySans-small font-bodySans-small text-bodySans-small leading-bodySans-small text-themes-color-text-dark;
}

#pr-reviewdisplay body .p-w-r .pr-review-display p,
#pr-reviewdisplay body .p-w-r .pr-review-display span {
  @apply flex items-center font-bodySans-medium leading-bodySans-medium text-bodySans-medium text-themes-color-text-dark;
}

body .p-w-r .pr-helpful-active span,
.p-w-r .pr-helpful-active[aria-disabled] span,
.p-w-r .pr-helpful-active[disabled] span {
  color: #707070;
}

body .p-w-r .pr-helpful-active .pr-thumbs-cuff-fill,
.p-w-r .pr-helpful-active .pr-thumbs-fill,
.p-w-r .pr-helpful-active[aria-disabled] .pr-thumbs-cuff-fill,
.p-w-r .pr-helpful-active[aria-disabled] .pr-thumbs-fill,
.p-w-r .pr-helpful-active[disabled] .pr-thumbs-cuff-fill,
.p-w-r .pr-helpful-active[disabled] .pr-thumbs-fill {
  @apply fill-themes-color-text-brand1;
}

#pr-reviewdisplay body .p-w-r .pr-rd-footer .pr-rd-bottomline {
  @apply md:mb-spacing-margin-large-3 mb-spacing-margin-large-5;
}

#pr-reviewdisplay body .p-w-r .pr-rd-footer span.pr-rd-bold {
  @apply font-bodySans-large-semibold font-bodySans-large-semibold text-bodySans-large-semibold leading-bodySans-large-semibold ml-0 text-themes-color-text-black md:text-themes-color-text-dark md:mb-0 mb-spacing-space-between-large-3 md:pr-2 !important;
}

#pr-reviewdisplay body .p-w-r .pr-rd-footer.pr-rd-content-block p span {
  @apply md:ml-spacing-space-between-large-2 ml-0;
}

#pr-reviewdisplay body .p-w-r .pr-rd-footer span.pr-rd-bold.pr-rd-helpful-text {
  @apply md:mb-0 mb-spacing-spacing-5 !important;
}

body .p-w-r .pr-review-display .pr-rd-merchant-response .pr-rd-merchant-response-headline {
  @apply md:font-header-large-xSmall md:font-header-large-xSmall md:text-header-large-xSmall md:leading-header-large-xSmall font-header-small-xSmall font-header-small-xSmall text-header-small-xSmall leading-header-small-xSmall text-themes-color-text-black mb-spacing-margin-large-3 !important;
}

body .p-w-r .pr-review-display .pr-rd-merchant-response .pr-rd-merchant-response-headline strong {
  @apply text-themes-color-text-black !important;
}

/* Section:3  Reviewed by section */

/* Section:3  Reviewed by section */

/* Power review styles for PLP */
.product-card-listing body .p-w-r .pr-snippet .pr-snippet-stars-png .pr-snippet-rating-decimal {
  @apply hidden;
}

body .p-w-r .pr-category-snippet__total {
  @apply hidden;
}

.product-card-listing body .p-w-r .pr-category-snippet {
  @apply items-start !important;
}

/* Begin: Footer */

body .p-w-r .pr-review-display .pr-rd-main-footer {
  @apply border-0 font-bodySans-large-semibold leading-bodySans-large-semibold mt-8 text-bodySans-large-semibold text-left !important;
}

body .p-w-r .pr-review-display .pr-rd-main-footer .pr-rd-review-position span {
  @apply font-bodySans-large-semibold leading-bodySans-large-semibold text-bodySans-large-semibold !important;
}

/* Power Review Pagination */
body .p-w-r .pr-read-review .pr-rd-pagination .pr-rd-pagination-btn:last-child,
body .p-w-r .pr-review-display .pr-rd-pagination .pr-rd-pagination-btn:last-child {
  padding-left: 0 !important;
}

body .p-w-r .pr-read-review.pr-rd-display-desktop .pr-rd-pagination,
body .p-w-r .pr-read-review.pr-rd-display-tablet .pr-rd-pagination,
body .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-pagination,
body .p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-pagination {
  padding-left: 0 !important;
}

body .p-w-r .pr-review-display .pr-rd-pagination {
  border-bottom: 1px solid !important;

  @apply flex absolute right-0 top-0 pt-0 mt-0 !border-themes-color-text-brand1;
}

.pr-rd-pagination-btn span {
  @apply !hidden;
}

body .p-w-r .pr-review-display .pr-rd-review-position {
  @apply mb-spacing-spacing-4;
}

/* End: Footer */

/* stylelint-disable selector-class-pattern */

/* BEGIN: Off brand overrides */

.Off #pr-reviewdisplay {
  @apply bg-themes-color-background-white;
}

.Off body .p-w-r .pr-review-snapshot {
  @apply bg-themes-color-background-white;
}

.Off body .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header {
  @apply bg-themes-color-background-white;
}

.Off body .p-w-r .pr-read-review .pr-rd-sort,
.Off body .p-w-r .pr-review-display .pr-rd-sort {
  @apply border border-components-dropdown-border-default rounded-full min-w-[280px];
}

/* END: Off brand overrides */
